<template>
  <div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <!-- <div class="_Select">
          <span>年：</span>
          <el-select v-model="currentYear" clearable placeholder="请选择">
            <el-option
              v-for="item in yearsList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="_Select">
          <span>公司：</span>
          <el-select
            v-model="company"
            @change="changCompy"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in cpmpyList"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span>部门：</span>
          <el-select
            v-model="dept"
            @change="changDept"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in deptList"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span>岗位：</span>
          <el-select v-model="post" clearable placeholder="请选择">
            <el-option
              v-for="item in postList"
              :key="item.value"
              :label="item.postName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="success" icon="el-icon-search" @click="getTableList"
          >搜索</el-button
        >
        <el-checkbox
          style="margin-left: 15px"
          label="仅看未被抽取过的题目"
          v-model="ischeck"
          @change="changBox"
        ></el-checkbox>
      </div>
      <div class="table">
        <el-table
          :height="elementHeight"
          :data="!ischeck ? tableList : filterList"
          style="width: 100%"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          id="table"
        >
          <el-table-column prop="question" label="问题" width="600">
          </el-table-column>
          <el-table-column prop="extractCount" label="抽取次数">
          </el-table-column>
          <el-table-column prop="probability" label="至今被抽取占比">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "../../api/http";
export default {
  data() {
    return {
      currentYear: "",
      company: "",
      dept: "",
      post: "",
      cpmpyList: this.$store.state.companyList,
      deptList: [],
      postList: [],
      tableList: [],
      filterList: [],
      yearsList: [], //年份控制在前后五年
      ischeck: false,
      elementHeight: null,
    };
  },
  mounted() {
    this.getElementHeight();
    this.handleYears();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 60 + 52 + 11);
      });
    },
    handleYears() {
      this.currentYear = new Date().getFullYear();
      var num = this.currentYear - 5;
      for (var i = 0; i < 9; i++) {
        this.yearsList.push((num = num + 1));
      }
    },
    changCompy(e) {
      this.deptList = [];
      this.postList = [];
      this.dept = "";
      this.post = "";
      if (!e) {
        return;
      }
      this.company = e;
      this.getDeptList();
    },
    getDeptList() {
      get("/api/Department/GetNoTree?CompId=" + this.company).then((resp) => {
        if (resp.code == 200) {
          this.deptList = resp.data;
        }
      });
    },
    changDept(e) {
      this.postList = [];
      this.post = "";
      if (!e) {
        return;
      }
      this.dept = e;
      this.getJob();
    },
    getJob() {
      get("/api/Post?CompId=" + this.company + "&DeptId=" + this.dept).then(
        (resp) => {
          if (resp.code == 200) {
            this.postList = resp.data;
          }
        }
      );
    },
    getTableList() {
      if (!this.post) {
        this.$message({
          type: "warning",
          message: "请选择公司部门岗位",
        });
        return;
      }
      get(
        "/api/FileAuditReport/GetFileAuditReport?CompId=" +
          this.company +
          "&DeptId=" +
          this.dept +
          "&PostId=" +
          this.post
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableList = resp.data;
        }
      });
    },
    changBox(e) {
      this.ischeck = e;
      if (this.ischeck) {
        this.filterList = [];
        this.tableList.map((item) => {
          if (item.extractCount == 0) this.filterList.push(item);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
._Select > span {
  width: 70px !important;
}
.table {
  border-top: 1px solid rgb(224, 224, 224);
  padding: 5px 20px;
  box-sizing: border-box;
}
.el-table {
  border: none !important;
}
</style>